import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject, ChangeEvent } from "react";
import {
  getStorageData,
  removeStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";

interface HeaderImage {
  url: string;
}
interface SearchQuery {
  data: {
    id: number;
    attributes: {
      query: string;
      second_query: string;
      language: string;
      search_type: string | null;
      status: string | null;
      inventor: string | null;
      assignee: string | null;
      patent_office: string | null;
      litigation: boolean;
      patents: boolean;
      publication: boolean;
      provisional: boolean;
      non_provisional: boolean;
      created_at: string;
      updated_at: string;
      key_features: string;
      user_string: string | null;
      before: string | null;
      after: string | null;
      products: string | null;
    };
  };
}

interface Attributes {
  id: number;
  welcome_note: string;
  email: string;
  contact: string;
  address: string;
  copyright:string;
  header_image: HeaderImage;
}

interface DataResponse {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Contact {
  data: DataResponse[];
  errors: [{ token: string }];
}
interface Attributes {
  user_name: string;
  full_phone_number: string;
  email: string;
  activated: boolean;
  image_url: string;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

interface Meta {
  token: string;
}

interface ProfileResponse {
  data: Data;
  meta: Meta;
  errors: Error[];
}

export interface ArticlesResponse {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    article_image: {
      url: string;
    };
    created_at: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  boxArr: { name: string }[];
  isDisabled: boolean;
  openModel: boolean;
  selectedArticle : any;
  searchboxArr: { name: string }[];
  priorityArr: { name: string }[];
  topicArr: { name: string }[];
  patentOfcArr: { name: string }[];
  toggleSwitch: boolean;
  searchString: string;
  filterCheckbox: string[];
  topicCheckbox: string[];
  startDate: string | Date | null;
  endDate: string | Date | null;
  languageArr: { name: string; value: string }[];
  selectedLanguage: string[];
  showShimmer: boolean;
  docModel: boolean;
  docUpload: File | null | undefined;
  progress: number;
  docUploadTextField: File | null | undefined;
  startSearch: boolean;
  generateFeaturesData: string[];
  loading: boolean;
  keywordGenerateData: string[];
  getAllRecentSearches: any;
  getAllSupportAnalysts:any;
  generateStringData: string[];
  contactData: DataResponse | null;
  advanceInput: string;
  userName: string;
  profilePhoto: string;
  priority: unknown;
  status: string;
  type: unknown;
  litigation: unknown;
  patentOffice: string[];
  inputFields: { name: string }[];
  inputAssignee: { name: string }[];
  inputInventor: string;
  inputFieldsAssignee: string;
  articleData: [];
  promptId: string;
  secondQuery: string;
  uploadTimeLeft: number | null;
  disableBtn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isDisabled: true,
      boxArr: [
        { name: "Patent" },
        { name: "Publication" },
        { name: "Products" },
        { name: "Provisional Specification" },
        { name: "Non-Provisional Specification" },
      ],
      searchboxArr: [
        { name: "Include" },
        { name: "Classification" },
        { name: "Chemistry" },
        { name: "Patent Numbers" },
      ],
      topicArr: [
        { name: "Full documents" },
        { name: "Title" },
        { name: "Abstract" },
        { name: "Claims" },
        { name: "All" },
        { name: "Any" },
        { name: "Exact" },
        { name: "Not" },
      ],
      toggleSwitch: false,
      searchString: "",
      filterCheckbox: ["Patent"],
      topicCheckbox: [],
      startDate: null,
      endDate: null,
      languageArr: [
        { name: "English", value: "ENGLISH" },
        { name: "German", value: "GERMAN" },
        { name: "Chinese", value: "CHINESE" },
        { name: "French", value: "FRENCH" },
        { name: "Spanish", value: "SPANISH" },
        { name: "Arabic", value: "ARABIC" },
        { name: "Japanese", value: "JAPANESE" },
        { name: "Korean", value: "KOREAN" },
        { name: "Portuguese", value: "PORTUGUESE" },
        { name: "Russian", value: "RUSSIAN" },
        { name: "Italian", value: "ITALIAN" },
        { name: "Dutch", value: "DUTCH" },
        { name: "Swedish", value: "SWEDISH" },
        { name: "Finnish", value: "FINNISH" },
        { name: "Norwegian", value: "NORWEGIAN" },
        { name: "Danish", value: "DANISH" },
      ],
      priorityArr: [
        { name: "Priority" },
        { name: "Filing" },
        { name: "Publication" },
      ],
      patentOfcArr: configJSON.patentOffice,
      selectedLanguage: [],
      showShimmer: false,
      docModel: false,
      docUpload: null,
      progress: 0,
      uploadTimeLeft: null,
      docUploadTextField: null,
      startSearch: false,
      generateFeaturesData: [],
      promptId: "",
      loading: false,
      keywordGenerateData: [],
      getAllRecentSearches: [],
      getAllSupportAnalysts: [],
      generateStringData: [],
      contactData: null,
      advanceInput: "",
      userName: "",
      profilePhoto: "",
      priority: "Priority",
      status: "",
      type: "",
      litigation: "",
      patentOffice: [],
      inputFields: [],
      inputAssignee: [],
      inputInventor: "",
      inputFieldsAssignee: "",
      articleData: [],
      secondQuery: "",
      disableBtn: false,
      selectedArticle:{},
      openModel: false,
    };

    this.fileInputRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.apiGenerateFeaturesCallID) {
      if (!responseJson.error) {
        this.setState({
          generateFeaturesData: responseJson?.key_features || [],
          promptId: responseJson.prompt_id,
          startSearch: true,
        });
        this.getKeywordGenerationApi();
      } else {
        this.setState({
          loading: true,
        });
      }
    }
    if (apiRequestCallId === this.apiKeywordGenerateCallID) {
      if (responseJson) {
        this.setState({
          keywordGenerateData: responseJson,
        });
        this.getGenerateStringApi();
      }
    }

    if (apiRequestCallId === this.apiRecentSearchResultsapicallId) {
      if (responseJson) {
        this.setState({
          getAllRecentSearches: responseJson,
        });
      }
    }

    if (apiRequestCallId === this.apiArticleListingCallId) {
      if (responseJson) {
        this.setState({
          articleData: responseJson?.data || [],
          showShimmer: false,
        });
        this.checkConditions();
      }
    }
    this.contactDetailsReceiveFunc(apiRequestCallId, responseJson);
    this.handleProfileResponse(apiRequestCallId, responseJson);
    this.generateStringRecive(apiRequestCallId, responseJson);
    this.googleSearchData(apiRequestCallId, responseJson);
    this.getAllArticleListeningData(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  // Customizable Area Start
  fileInputRef: RefObject<HTMLInputElement>;
  timer: NodeJS.Timeout | null = null;
  apiGenerateFeaturesCallID: string = "";
  apiKeywordGenerateCallID: string = "";
  apiGenerateStringCallID: string = "";
  apiContectDetailsCallId: string = "";
  apiGetProfileID: string = "";
  apiArticleListingCallId: string = "";
  apiRecentSearchResultsapicallId: string = ""
  apiGoogleSearchDataCallID: string = "";
  apiSupportAnalystsapicallId:string = "";
  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getAllArticleListeningData = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiSupportAnalystsapicallId) {
      if (responseJson) {
        this.setState({
          getAllSupportAnalysts: responseJson,
        });
      }
    }
  };

  generateStringRecive = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiGenerateStringCallID) {
      if (responseJson) {
        const advanceInput =
          (responseJson?.string_1 || "") + " " + (responseJson?.string_2 || "");
        const secondQuery = responseJson?.string_2 || "";
        this.setState({
          generateStringData: responseJson,
          advanceInput: advanceInput,
          secondQuery: secondQuery,
          toggleSwitch: true,
          disableBtn: true,
          loading: false,
        });
      }
    }
  };
  /* istanbul ignore next */
  googleSearchData = (apiRequestCallId: string, responseJson: SearchQuery) => {
    if (apiRequestCallId !== this.apiGoogleSearchDataCallID || !responseJson) {
      return;
    }

    const {
      before,
      after,
      products,
      publication,
      provisional,
      non_provisional,
      inventor,
      assignee,
      litigation,
      status,
      language,
      query,
      user_string,
      search_type,
      patent_office,
      key_features,
    } = responseJson?.data?.attributes;

    this.handleBeforeDate(before);
    this.handleAfterDate(after);

    const parseSelectedCheck = this.getSelectedChecks(products, publication,provisional,non_provisional);
    const { inventorName, multiInventor } = this.parseInventorData(inventor);
    const { assigneeName, multiAssign } = this.parseAssigneeData(assignee);
    const litigationStatus = this.getLitigationStatus(litigation);
    const pasreKeyFeature = JSON.parse(key_features);
    this.setState({
      status: status || "",
      litigation: litigationStatus || "",
      selectedLanguage: language?.split(",") || [],
      advanceInput: query || "",
      searchString: user_string || "",
      type: search_type || "",
      patentOffice: patent_office?.split(",") || [],
      filterCheckbox: parseSelectedCheck || [],
      inputFields: multiInventor || [],
      inputInventor: inventorName,
      inputFieldsAssignee: assigneeName || "",
      inputAssignee: multiAssign || [],
      generateFeaturesData: pasreKeyFeature || [],
      disableBtn: true,
    });
  };


  handleModelClose = () => {
    this.setState({
      openModel: false,
    })
  }

  handleModelOpen = (article: any) => {
    this.setState({ openModel: true, selectedArticle: article });
  };

  handleBeforeDate = (beforeString: string | null) => {
    if (!beforeString) {
      return;
    }
    const [key, date] = beforeString.split(":");
    this.setState({
      priority: key || "",
      endDate: date || null,
    });
  };

  handleAfterDate = (afterString: string | null) => {
    if (!afterString) {
      return;
    }
    const afterDate = afterString.split(":")[1];
    this.setState({
      startDate: afterDate || null,
    });
  };

  getSelectedChecks = (products: string | null, publication?: boolean,provisional?:boolean,non_provisional?:boolean) => {
    const checks = ["Patent"];
    if (products) {
      checks.push("Products");
    }
    if (publication) {
      checks.push("Publication");
    }
    if(provisional)
    {
      checks.push("Provisional Specification");
    }
    if(non_provisional)
    {
      checks.push("Non-Provisional Specification");
    }
    return checks;
  };

  parseInventorData = (inventorString: string | null) => {
    if (!inventorString) {
      return { inventorName: "", multiInventor: [] };
    }
    const inventorData = inventorString.split(",");
    const inventorName = inventorData.shift() || "";
    const multiInventor = inventorData.map((item) => ({ name: item }));
    return { inventorName, multiInventor };
  };

  parseAssigneeData = (assigneeString: string | null) => {
    if (!assigneeString) {
      return { assigneeName: "", multiAssign: [] };
    }
    const assignData = assigneeString.split(",");
    const assigneeName = assignData.shift() || "";
    const multiAssign = assignData.map((item) => ({ name: item }));
    return { assigneeName, multiAssign };
  };

  getLitigationStatus = (litigation: boolean) => {
    if (litigation === true) {
      return "Has Related Litigation";
    }
    if (litigation === false) {
      return "No Known Litigation";
    }
    return null;
  };

  toggleIOSSwitch = () => {
    this.setState((prevState) => ({
      toggleSwitch: !prevState.toggleSwitch,
    }));
  };

  handleSearchChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newSearchString = event.target.value;
    this.setState({ searchString: newSearchString });
    localStorage.setItem('searchString',newSearchString);
  };

  handleAdvanceSearch = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    this.setState({
      advanceInput: event.target.value,
    });
  };

  handleCheckBoxChange = (event: {
    target: { checked: boolean; value: string };
  }) => {
    let checkBoxes = [...this.state.filterCheckbox];
    if (event.target.checked) {
      checkBoxes.push(event.target.value);
    } else {
      let removeIndex = checkBoxes.indexOf(event.target.value);
      if (removeIndex !== -1) {
        checkBoxes.splice(removeIndex, 1);
      }
    }
    setStorageData("selectedCheck", JSON.stringify(checkBoxes));
    this.setState({ filterCheckbox: checkBoxes });
  };

  handleTopicCheckBoxChange = (event: {
    target: { checked: boolean; value: string };
  }) => {
    let topicCheckBoxes = [...this.state.topicCheckbox];
    if (event.target.checked) {
      topicCheckBoxes.push(event.target.value);
    } else {
      let removeIndex = topicCheckBoxes.indexOf(event.target.value);
      if (removeIndex !== -1) {
        topicCheckBoxes.splice(removeIndex, 1);
      }
    }
    this.setState({ topicCheckbox: topicCheckBoxes });
  };

  formatDate = (date: string | Date | null): string => {
    if (date) {
      const dateString = new Date(date);
      const year: number = dateString.getFullYear();
      const month: string = String(dateString.getMonth() + 1).padStart(2, "0");
      const day: string = String(dateString.getDate()).padStart(2, "0");

      return `${year}${month}${day}`;
    } else {
      return "";
    }
  };

  handleStartDate = (date: string) => {
    this.setState({ startDate: date });
  };

  handleEndDate = (date: string) => {
    this.setState({ endDate: date });
  };

  handleLanguageChange = (value: string[]) => {
    this.setState({ selectedLanguage: value });
  };

  disableViewResult = () => {
    return this.state.generateStringData?.length === 0;
  };
  handleUploadClick = () => {
    this.setState({
      docModel: false,
      progress: 0,
      docUploadTextField: this.state.docUpload,
      searchString: "",
    });
    if (this.timer) {
      clearInterval(this.timer);
    }
  };
  handleUploadFile = (file: File) => {
    this.setState({ docUpload: file, uploadTimeLeft: 2, progress: 100 });

    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.uploadTimeLeft && prevState.uploadTimeLeft > 0) {
          return { uploadTimeLeft: prevState.uploadTimeLeft - 1, progress: prevState.progress };
        } else {
          return { uploadTimeLeft: null, progress: 100 };
        }
      });
    }, 1000); 
  };

  handleRemoveFile = () => {
    this.setState({
      docUpload: null,
      progress: 0,
      docUploadTextField: null,
    });
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  async checkConditions() {
    const screenWidth = window.innerWidth;
    const array = this.state.articleData;
    const arrayLength = array.length;
    // Disable button if screen width is less than 768px or array length is 0
    if (
      (screenWidth < 651 && arrayLength > 1) ||
      (screenWidth < 1051 && arrayLength > 2) ||
      (screenWidth < 1441 && arrayLength > 3) ||
      (screenWidth > 1441 && arrayLength > 4)
    ) {
      this.setState({ isDisabled: false });
    } else {
      this.setState({ isDisabled: true });
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    if (!token) {
      this.goToLogInPage();
    }
    this.contectDetailsAPI();
    this.handleGetUserProfileApi();
    this.articleListingAPI();
    this.handleLoaderFile();
    this.getAllRecentSearchesAPI()
    this.getSupportAnalystsAPI();

    window.addEventListener("beforeunload", this.handleBeforeUnload);
    removeStorageData("selectResult")
    removeStorageData("prompt_id");
    removeStorageData("prrovisonalid");
    removeStorageData("nonprrovisonalid");
    const serachId = await getStorageData("searchId");
    if (serachId) {
      this.getGoogleSearchDataApi(serachId);
    }
    const serachAgain = await getStorageData("advanchData");
    if (serachAgain) {
      this.setState({
        searchString: serachAgain,
      });
    }
    const savedSearchString = localStorage.getItem('searchString')||'';
    this.setState({searchString:savedSearchString})
  }

  handleBeforeUnload = () => {
    removeStorageData("stringData")
    removeStorageData("inventor")
    removeStorageData("assignee")
    removeStorageData("patentOffice")
    removeStorageData("startDate")
    removeStorageData("language")
    removeStorageData("status")
    removeStorageData("type")
    removeStorageData("litigation")
    removeStorageData("priority");
    removeStorageData("backToList");
    removeStorageData("generateFeaturesData")
  };

  handleLoaderFile = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.progress === 100) {
          clearInterval(this.timer as NodeJS.Timeout);
          return { progress: 100 };
        }
        const diff = Math.random() * 10;
        const newProgress = Math.min(prevState.progress + diff, 100);
        if (newProgress === 100) {
          clearInterval(this.timer as NodeJS.Timeout);
        }
        return { progress: newProgress };
      });
    }, 500);
  };

  async componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  docModelopen = () => {
    this.setState({ docModel: true });
  };

  docModelClose = () => {
    this.setState({
      docModel: false,
      docUpload: null,
      progress: 0,
    });
    if (this.timer) {
      clearInterval(this.timer);
    }
  };

  handleUpload = () => {
    this.fileInputRef?.current?.click();
  };

  handleUploadDoc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.handleLoaderFile();
      this.setState({ docUpload: file });
    }
  };
  
  handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); 
    event.stopPropagation();
  
    const files = event.dataTransfer.files; 
    if (files.length > 0) {
      const file = files[0]; 
      this.handleLoaderFile();
      this.setState({ docUpload: file });
    }
  };
  
  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  
  handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  handleClickNewSearch = () => {
    this.setState({
      advanceInput: "" 
    })
    this.getGenerateFeaturesApi();
  };

  handleErrorRes = (errorRes: { token: string }[]) => {
    if (errorRes[0].token) {
      this.goToLogInPage();
    }
  };

  getGenerateFeaturesApi = async (advance?: string) => {
    const header = {
      token: await getStorageData("token"),
    };

    const formData = new FormData();
    this.state.searchString &&
      formData.append("content", this.state.searchString);
    this.state.docUpload && formData.append("content", this.state.docUpload);
    advance && formData.append("content", advance);

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGenerateFeaturesCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.generateFeaturesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getKeywordGenerationApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiKeywordGenerateCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.keywordGenerateEndPoint}${this.state.generateFeaturesData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getGenerateStringApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGenerateStringCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.generateStringEndPoint}${this.state.keywordGenerateData}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  goToKeyFeaturePage = async () => {
    setStorageData("promptId", this.state.promptId)
    setStorageData("secondQuery", this.state.secondQuery)
    await setStorageData("advanchData", this.state.searchString)
    removeStorageData("BackTrue")
    const dateObj = {
      startDate: this.formatDate(this.state.startDate),
      endDate: this.formatDate(this.state.endDate),
    };

    const inventor = [
      ...this.state.inputFields,
      { name: this.state.inputInventor },
    ];
    const assignee = [
      ...this.state.inputAssignee,
      { name: this.state.inputFieldsAssignee },
    ];

    const combinedData = {
      dateObj,
      inventor,
      assignee,
      firstQury: this.state.advanceInput,
      advanchData: this.state.searchString,
      patentOffice: this.state.patentOffice,
      language: this.state.selectedLanguage,
      inventorData: inventor,
      assigneeData: assignee,
      status: this.state.status,
      type: this.state.type,
      litigation: this.state.litigation,
      priority: this.state.priority,
      selectedCheck: this.state.filterCheckbox,
      promptId: this.state.promptId,
      featuresData: this.state.generateFeaturesData,
      secondQuery: this.state.secondQuery,
    };

    const msgData: Message = new Message(
      getName(MessageEnum.NavigationMessageSendData)
    );
    msgData.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msgData.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      "KeyFeatureLandingPageWeb"
    );
    msgData.addData(getName(MessageEnum.AdvancedSearchDetails), combinedData);
    this.send(msgData);
  };


  goToLogInPage = async () => {
    removeStorageData("token");
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  contactDetailsReceiveFunc = (
    apiRequestCallId: string,
    responseJson: Contact
  ) => {
    if (apiRequestCallId === this.apiContectDetailsCallId) {
      if (responseJson?.errors?.length) {
        this.handleErrorRes(responseJson.errors);
      }
      if (responseJson?.data) {
        this.setState({
          contactData: responseJson.data[0],
        });
        const getCopyright = (responseJson:any) => {
          if (responseJson && responseJson.data && responseJson.data[0] && responseJson.data[0].attributes) {
            return responseJson.data[0].attributes.copyright;
          }
        };
        const contactData = {
          contactEmail: responseJson?.data?.[0]?.attributes?.email,
          contactPhone: responseJson?.data?.[0]?.attributes?.contact,
          contactAddress: responseJson?.data?.[0]?.attributes?.address,
          copyright:(getCopyright(responseJson))
        };
        setStorageData("contactData", JSON.stringify(contactData));
      }
    }
  };

  handleProfileResponse = (
    apiRequestCallId: string,
    responseJson: ProfileResponse
  ) => {
    if (apiRequestCallId === this.apiGetProfileID) {
      if (responseJson.data && responseJson.data.attributes) {
        this.setState({
          userName: responseJson.data.attributes?.user_name,
          profilePhoto: responseJson.data.attributes?.image_url,
        });
        const userProfile = {
          userName: responseJson.data.attributes.user_name,
          profileImg: responseJson.data.attributes.image_url,
          email: responseJson.data.attributes.email,
        };
        setStorageData("userProfile", JSON.stringify(userProfile));
      }
    }
  };

  contectDetailsAPI = async () => {
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiContectDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetUserProfileApi = async () => {
    const headers = {
      token: await getStorageData("token"),
    };

    const getProfileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileID = getProfileMessage.messageId;

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPoint
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getProfileMessage.id, getProfileMessage);
  };

  handlePriorityChange = (
    event: React.ChangeEvent<{ value: unknown; checked: boolean }>
  ) => {
    const { checked, value } = event.target;
    if (checked) {
      this.setState({
        priority: value,
      });
    } else {
      this.setState({
        priority: "",
      });
    }
  };
  handleStatusChange = (
    event: React.ChangeEvent<{ value: string; checked: boolean }>
  ) => {
    const { checked, value } = event.target;
    if (checked) {
      this.setState({
        status: value,
      });
    } else {
      this.setState({
        status: "",
      });
    }
  };

  handleChangeType = (
    event: React.ChangeEvent<{ value: unknown; checked: boolean }>
  ) => {
    const { checked, value } = event.target;
    if (checked) {
      this.setState({
        type: value,
      });
    } else {
      this.setState({ type: "" });
    }
  };
  handleChangeLitigation = (
    event: React.ChangeEvent<{ value: unknown; checked: boolean }>
  ) => {
    const { checked, value } = event.target;
    if (checked) {
      this.setState({
        litigation: value,
      });
    } else {
      this.setState({
        litigation: "",
      });
    }
  };
  handleChangePatentOffice = (value: string[]) => {
    this.setState({ patentOffice: value });
  };
  addMoreInput = () => {
    this.setState({
      inputFields: [...this.state.inputFields, { name: "" }],
    });
  };

  handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const inputFields = [...this.state.inputFields];
    inputFields[index] = {
      ...inputFields[index],
      [name as keyof { name: string }[]]: value,
    };
    this.setState({ inputFields });
  };

  handleaddMoreInput = () => {
    this.setState({
      inputAssignee: [...this.state.inputAssignee, { name: "" }],
    });
  };

  handleAssigneeChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    const inputAssignee = [...this.state.inputAssignee];
    inputAssignee[index] = {
      ...inputAssignee[index],
      [name as keyof { name: string }[]]: value,
    };
    this.setState({ inputAssignee });
  };

  handleInputInventor = (event: { target: { value: string } }) => {
    this.setState({ inputInventor: event.target.value });
  };

  handleAssigneeInventor = (event: { target: { value: string } }) => {
    this.setState({ inputFieldsAssignee: event.target.value });
  };
  articleListingAPI = async () => {
    this.setState({
      showShimmer: true,
    });
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiArticleListingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getArticleAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  redirectToRecentSearch =(case_id:number)=>{
    setStorageData("searchHistory", JSON.stringify(case_id))
  }
  getAllRecentSearchesAPI = async () => {
    const header = {
      token: await getStorageData("token"),
    };
    const abstract = "5"
    const page = "1"

    const params = new URLSearchParams({
      per_page: abstract,
      page: page,
    }).toString();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiRecentSearchResultsapicallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/landing_pages/recent_searches?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSupportAnalystsAPI = async () => {
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSupportAnalystsapicallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSupportAnalysts
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleClearAll = () => {
    this.setState({
      priority: "",
      startDate: null,
      endDate: null,
      advanceInput: "",
      inputInventor: "",
      inputFields: [],
      inputFieldsAssignee: "",
      inputAssignee: [],
      patentOfcArr: [],
      searchString: '',
      selectedLanguage: [],
      generateFeaturesData: [],
      status: "",
      type: "",
      litigation: "",
      filterCheckbox: ["Patent"],
    });
  };

  getGoogleSearchDataApi = async (index: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGoogleSearchDataCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGoogleSearchDataAPIEndPoint}?id=${index}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
