import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";


interface Patent {
  position: number;
  rank: number;
  patent_id: string;
  serpapi_link: string;
  title: string;
  snippet: string;
  abstract: string;
  priority_date: string;
  filing_date: string;
  grant_date: string;
  publication_date: string;
  inventor: string;
  assignee: string;
  publication_number: string;
  language: string;
  pdf: string;
  thumbnail?: string;
  images?: string[];
  figures?: string[];
  country_status: {
    [countryCode: string]: string;
  };
};
interface Publication {
  position: number | null,
  rank: number | null,
  scholar_id: string,
  serpapi_link: string,
  is_scholar: boolean,
  title: string,
  snippet: string,
  url_hostname: string,
  author: string,
  publication_date: string
};

interface Product {
  position: number,
  title: string,
  link: string,
  product_link: string,
  product_id: string,
  serpapi_product_api: string,
  number_of_comparisons: string,
  comparison_link: string,
  serpapi_product_api_comparisons: string,
  source: string,
  price: string,
  extracted_price: number,
  rating: number,
  reviews: number,
  extensions: string[],
  thumbnail: string,
  tag: string,
  delivery: string,
}

interface DateObject {
  startDate: string;
  endDate: string;
}

interface Person {
  name: string;
}

interface Start {
  startDate: string;
  endDate: string;
}

interface JSONResponse {
  dateObj: DateObject;
  inventor: Person[];
  assignee: Person[];
  firstQury: string;
  advanchData: string;
  start: Start;
  patentOffice: string;
  language: string;
  inventorData: Person[];
  assigneeData: Person[];
  status: string;
  type: string;
  litigation: string;
  priority: string;
  selectedCheck: string[];
  promptId: string;
  featuresData: string;
  secondQuery: string;
  products: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  publicationerror:boolean;
  patenterrors:boolean;
  selectedTabs:  string[];
  overlappingData: {label: string, value: number}[];
  girdView: boolean;
  selectResult: string;
  downReportModel: boolean;
  loading: boolean;
  patentSerpData: Patent[];
  caseid:string;
  publicationSerpData: Publication[];
  selectedPatentNumbers: string[];
  selectedPublicationNumbers: string[];
  selectedProductNumbers:string[];
  productDetails: Product[]
  searchId: number | null
  clearTrigger:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class KeyFeatureLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AdvancedSearchDetails),
      getName(MessageEnum.PatentDetails)
    ];

    this.state = {
      publicationerror:true,
      patenterrors:true,
      caseid :"",
      selectedTabs: ["Patent"],
      overlappingData: [
        { label: 'Total Overlapping', value: 70 },
        { label: 'Abstarct', value: 30 },
        { label: 'Description', value: 40 },
        { label: 'Claims', value: 50 },
      ],
      girdView: true,
      selectResult: "",
      downReportModel: false,
      loading: false,
      patentSerpData: [],
      publicationSerpData: [],
      selectedPatentNumbers: [],
      selectedPublicationNumbers:[],
      selectedProductNumbers:[],
      productDetails: [],
      searchId: null,
      clearTrigger:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiProductDetailsCallID) {
      if (responseJson?.errors?.length) {
        this.setState({
          loading: false
        })
      } else if (responseJson?.length !== 0) {
        this.setState({ productDetails: responseJson })
      }
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let createContractData = message.getData(getName(MessageEnum.AdvancedSearchDetails))
      if (createContractData) {
        this.patentAndPublicationSerpApi(createContractData)
        const product = createContractData?.selectedCheck?.includes('Products')
        if (product) {
          this.postProductDetails(createContractData)
        }
      }
    }

    this.handleSerpCallReceive(apiRequestCallId,responseJson)
    this.handleSearchDataReceive(apiRequestCallId,responseJson)
    this.handlePublicationReceive(apiRequestCallId,responseJson)
    this.handleProductListReceive(apiRequestCallId,responseJson)
    this.handleDownloadReport(apiRequestCallId,responseJson)
    // Customizable Area End
  }

  // Customizable Area Start
  apiSerpCallID: string = "";
  apiProductDetailsCallID : string = ""
  apiSearchDetailCallID: string = ""
  apiGetPublicationListCallId: string = ""
  apiGetProductListCallId: string = ""
  apiGetProvisionalListCallId:string=""
  apiGetPatentDownloadreportCallId: string = ""
  apiGetPublicationDownloadreportCallId:string = ""
  apiGetProductDownloadreportCallId:string = ""
  async componentDidMount() {
    const loginToken = await getStorageData("token")
    const selectResultValue = await getStorageData("selectResult")
    const parseselectResultValue = JSON.parse(selectResultValue);
    if(!loginToken){
      this.goingToLogInPage()
    }
    let ListData = await getStorageData("BackTrue")
    if(ListData) {
      this.getSearchDetailDataApi()
    }
    let selectTab = await getStorageData("selectedCheck")
    let caseid=await getStorageData("caseid")
    let parsSelectTab = JSON.parse(selectTab)
    if(selectTab ) {
      this.setState({selectedTabs: parsSelectTab , selectResult:parseselectResultValue ? parseselectResultValue :  parsSelectTab[0]}, )
    }
    if(caseid){
      this.setState({caseid:caseid})
    }
    if(ListData && parsSelectTab.includes("Products")) {
      this.getProductListAPI()
    }

    if(ListData && parsSelectTab.includes("Publication")) {
      this.getPublicationListAPI()
    }
  }


  handleSerpCallReceive = (apiRequestCallId: string, responseJson:{case_id:string,patents:any[],publications:Publication[],search_id: number,errors:[{token: string}]}) => {
    if (apiRequestCallId === this.apiSerpCallID) {
      if (responseJson.errors?.length) {
        this.setState({
          loading: false
        },() => {
          setStorageData("searchId",this.state.searchId)
          setStorageData("BackTrue","BackTrue")
        })
      }
      const tokenExpire = responseJson.errors?.[0]?.token
      const resp:any=responseJson
      if (tokenExpire) {
        this.goingToLogInPage()
      }
      else if(resp.patents[0].errors)
      {
        this.setState({patenterrors:false,loading: false})
      }
      if(resp.publications.length>=1 && resp.publications[0].error)
      {
            this.setState({publicationerror:false,searchId: responseJson.search_id,caseid:responseJson.case_id})
      }
      if (responseJson) {
        setStorageData("caseid",responseJson.case_id)
        this.setState({
          patentSerpData: responseJson?.patents || [],
          publicationSerpData: responseJson.publications || [],
          searchId: responseJson.search_id,
          loading: false,
          caseid:responseJson.case_id
        })
      }
      setStorageData("searchId",responseJson.search_id)
      setStorageData("BackTrue","BackTrue")

      console.log("Hellllo keyfeaturelandingpage reciver",this.state.searchId)

    }
  }

  handleSearchDataReceive = (apiRequestCallId: string, responseJson:{error: string , errors: string}) => {
    if(apiRequestCallId === this.apiSearchDetailCallID) {
      if(!responseJson.error && !responseJson.errors) {
        const res = (responseJson as any).slice(0, -1);
        this.setState({
          patentSerpData:res as unknown as Patent[],
          loading: false
        })
      }
      const resp:any=this.state.patentSerpData[0]
      if(resp.errors)
      {
          this.setState({patenterrors:false})
      }
    }
  }

  handlePublicationReceive = (apiRequestCallId: string, responseJson:Publication[]) => {
    if(apiRequestCallId === this.apiGetPublicationListCallId) {
      this.setState({
        publicationSerpData:responseJson.slice(0, -1), // All except the last itemesponseJson,
        loading: false
      })
      const resp:any=this.state.publicationSerpData[0]
      if(resp.error)
      {
          this.setState({publicationerror:false})
      }
    }
  }

  handleProductListReceive = (apiRequestCallId: string, responseJson:Product[]) => {
    if(apiRequestCallId == this.apiGetProductListCallId) {
      if(responseJson) {
        this.setState({
          productDetails:responseJson,
          loading: false
        })
      }
    }
  }
  handleDownloadReport = (apiRequestCallId: string, responseJson: Product[]) => {
    if (apiRequestCallId == this.apiGetPatentDownloadreportCallId) {
      if (responseJson) {
        this.setState({
          selectedPatentNumbers: []
        })
        this.downloadModelClose()
      }
    } else  if (apiRequestCallId == this.apiGetPublicationDownloadreportCallId) {
      if (responseJson) {
        this.setState({
           selectedPublicationNumbers:[],
           clearTrigger:true
        })
        this.downloadModelClose()
      }
    } else  if (apiRequestCallId == this.apiGetProductDownloadreportCallId) {
      if (responseJson) {
        this.setState({
           selectedProductNumbers:[],
           clearTrigger:true
        })
        this.downloadModelClose()
      }
    }
  }

  onChangepatentselect = (publicationNumber: string) => {
    this.setState((prevState) => {
      const isAlreadySelected = prevState.selectedPatentNumbers.includes(publicationNumber);
      const updatedSelection = isAlreadySelected
        ? prevState.selectedPatentNumbers.filter((num) => num !== publicationNumber)
        : [...prevState.selectedPatentNumbers, publicationNumber];

      return { selectedPatentNumbers: updatedSelection };
    });
  };

  goBackToLandingPage = async () => {
    console.log("Hellllo keyfeaturelandingpage  goback",this.state.searchId)
    removeStorageData("BackTrue")
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageWeb"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleClickGridView = () => {
    this.setState(prevState => ({
      girdView: !prevState.girdView
    }));
  };

  onClickSetStutus = (value:string) => {
    setStorageData("selectResult", JSON.stringify(value))
    this.setState({selectResult:value})
  }

  downloadModelOpen = () => {
    this.setState({ downReportModel: true })
  };
  downloadDetails = () => {
    if ((this.state.selectedPatentNumbers.length > 0) || (this.state.selectedPublicationNumbers.length > 0) || (this.state.selectedProductNumbers.length > 0)) {
      this.setState({clearTrigger:false})
      if(this.state.selectResult === "Patent" ){
      this.downloadModelOpen()
      this.getPatentDownloadreport(this.state.selectedPatentNumbers)
    } else if(this.state.selectResult === "Publication" ){
      this.downloadModelOpen()
      this.getPublicationDownloadreport(this.state.selectedPublicationNumbers)
    }else if(this.state.selectResult === "Products" ){
      this.downloadModelOpen()
      this.getProductDownloadReport(this.state.selectedProductNumbers)
    }
    } 
  }

  downloadModelClose = () => {
    this.setState({ downReportModel: false })
  };

  goToShowMappingPage = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageShowMapping"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  goingToLogInPage = async () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    messageLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    messageLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageLogin);
  };
  patentAndPublicationSerpApi = async (createContractData:JSONResponse) => {    
    const header = {
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });
    const valuesArray = createContractData?.inventorData?.map((obj: { name: string; }) => obj.name);
    const stringRepresentation = valuesArray.join(', ');

    const valuesAssignArray = createContractData?.assigneeData?.map((obj: { name: string; }) => obj.name);
    const stringAssign = valuesAssignArray.join(', ');

    const formData = new FormData();
    let isPatent = false
    let isPublication = false
    let isProducts = false
    let isnon_provisional=false
    let isprovisional=false
    let promptId = await getStorageData("promptId")
    let secondQuery = await getStorageData("secondQuery")
    if(createContractData?.selectedCheck && createContractData?.selectedCheck?.length){
      isPatent = createContractData?.selectedCheck.includes("Patent");
      isPublication = createContractData?.selectedCheck.includes("Publication");
      isProducts = createContractData?.selectedCheck.includes("Products");
      isprovisional = createContractData?.selectedCheck.includes("Provisional Specification");
      isnon_provisional = createContractData?.selectedCheck.includes("Non-Provisional Specification");
    }
    let  isLitigation = createContractData?.litigation === 'Has Related Litigation' ? "YES" : "NO"
    formData.append("query", createContractData?.firstQury);
    formData.append("second_query", secondQuery);
    formData.append("patents", JSON.stringify(isPatent));
    formData.append("publication", JSON.stringify(isPublication));
    formData.append("provisional", JSON.stringify(isprovisional));
    formData.append("non_provisional", JSON.stringify(isnon_provisional));

    promptId && formData.append("prompt_id", promptId);
    createContractData?.type && formData.append("type", createContractData?.type)
    isLitigation && formData.append("litigation", isLitigation)
    createContractData?.patentOffice.length !== 0 && formData.append("country", createContractData?.patentOffice)
    createContractData?.status && formData.append("status", createContractData?.status)
    createContractData?.language.length !== 0 && formData.append("language", createContractData?.language)
    stringRepresentation && formData.append("inventor", stringRepresentation)
    stringAssign && formData.append("assignee", stringAssign)
    createContractData?.dateObj?.endDate && formData.append("before", `${createContractData?.priority}:${createContractData?.dateObj?.endDate}`)
    createContractData?.dateObj?.startDate && formData.append("after", `${createContractData?.priority}:${createContractData?.dateObj?.startDate}`)
    formData.append("key_features", JSON.stringify(createContractData?.featuresData))
    formData.append("content", createContractData?.advanchData)
    isProducts && formData.append("products", JSON.stringify(isProducts))

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSerpCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patentSerpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postProductDetails = async (createContractData:{firstQury: string}) => {
    const header = {
      token: await getStorageData("token"),
    };

    this.setState({ loading: true });

    const formData = new FormData();
    let secondQuery = await getStorageData("secondQuery")
    formData.append("query", createContractData?.firstQury);
    formData.append("second_query", secondQuery);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProductDetailsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postProductAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSearchDetailDataApi = async () => {
    this.setState({loading: true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSearchDetailCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchDetailAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPublicationListAPI = async () => {
    this.setState({loading: true})
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPublicationListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProductListAPI = async () => {
    this.setState({loading: true})
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProductListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProductListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setSelectedPublicationNumbers=(data:any)=>{
   this.setState({
    selectedPublicationNumbers:data
   })
  }
  seTselectedProductNumbers=(data:any)=>{
    this.setState({
     selectedProductNumbers:data
    })
   }


  getPatentDownloadreport = async (selectedPatentNumbers: any) => {
    const header =
    {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPatentDownloadreportCallId = requestMessage.messageId;

    const params = new URLSearchParams();

    selectedPatentNumbers.forEach((number: any) => {
      params.append("publication_numbers", number);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/patent_download_report.pdf?${params.toString()}`
    );
    requestMessage.addData
      (
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

    requestMessage.addData
      (
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getPublicationDownloadreport = async (selectedPublicationNumbers: any) => {

    const header =
    {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetPublicationDownloadreportCallId = requestMessage.messageId;

    const params = new URLSearchParams();

    selectedPublicationNumbers.forEach((number: any) => {
      params.append("scholar_ids", number);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/publication_download_report?${params.toString()}`
    );
    requestMessage.addData
      (
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

    requestMessage.addData
      (
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProductDownloadReport = async (selectedProductNumbers: any) => {

    const header =
    {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetProductDownloadreportCallId = requestMessage.messageId;

    const params = new URLSearchParams();

    selectedProductNumbers.forEach((number: any) => {
      params.append("product_ids", number);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/products_download_report?${params.toString()}`
    );
    requestMessage.addData
      (
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

    requestMessage.addData
      (
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
